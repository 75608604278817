import React, { useEffect } from 'react';
import { InputLabel, TextField, styled } from "@mui/material";
//import Textarea from '@mui/joy/Textarea';

interface TextFieldProps {
    objJson: any;
    value: string;
    onChange: (value: string) => void;
}

const TextboxField: React.FC<TextFieldProps> = ({
    objJson,
    value,
    onChange,
}) => {

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newValue = e.target.value;

        // Check if length exceeds 1000 characters
        if (newValue.length > 1000) {
            // Truncate the text to 1000 characters
            newValue = newValue.slice(0, 1000);
        }

        onChange(newValue);
    };

    useEffect(() => {
        // Listen to paste event on the document
        document.addEventListener('paste', handlePaste);
        return () => {
            // Remove the event listener when component unmounts
            document.removeEventListener('paste', handlePaste);
        };
    }, []); // Run effect only once on component mount

    const handlePaste = (e: ClipboardEvent) => {
    const target = e.target as HTMLElement;

    // Check if the paste event originated from the input field
    if (target.matches('input') || target.matches('textarea')) {
        // Clear clipboard data to prevent pasting
        e.preventDefault();
        // Optionally, notify the user
        console.log('Pasting is not allowed.');
    }
    };
    return (
        <div>
            <InputLabel required={objJson.required}>{objJson.label}</InputLabel>
            {(objJson?.textArea == undefined || objJson?.textArea === false) &&
                <TextField size="small"
                sx={{ width: '100%', height: '30px' }}
                    id="outlined-basic"
                    variant="outlined"
                    placeholder={objJson.placeholder}
                    value={value}
                    inputProps={{
                        maxLength: 1000,
                    }}
                    onChange={(e) => {
                        let inputValue = e.target.value;

                        if (inputValue.length > 1000) {
                            inputValue = inputValue.substring(0, 1000);
                        }
                        onChange(inputValue)
                }}
                disabled={objJson.disabled}
            />
            }
           
        </div>
    );

    /*return (
        <div>
            <InputLabel required={objJson.required}>{objJson.label}</InputLabel>
            <TextField size="small"
                sx={{ width: '100%', height: '30px' }}
                id="outlined-basic"
                variant="outlined"
                placeholder={objJson.placeholder}
                value={value}
                onChange={(e) => { onChange(e.target.value) }} />
        </div>
    );*/
};

export default TextboxField;