//import { Button } from '@mui/material';
import { Box, Button, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from "react-router-dom";


const Dashboard = () => {

    const linkTo = useNavigate();
    const handleBackToGrid = () => {
        setTimeout(() => {
            linkTo("/Create", {});
        }, 50);
    };

    return (
        <div>
            <h1>Welcome to the Artwork!</h1>
            <Stack >
                <Box sx={{ width: '100%', typography: 'body1', flex: 1, marginRight: '16px' }}>
                    <Button variant="contained" size="small" type="button" onClick={handleBackToGrid}>Create</Button>
                </Box>
            </Stack>
        </div>
    );
}

export default Dashboard;
