import axios from 'axios';
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Cookies from 'js-cookie'
const basicURL = 'https://artwork-contentapi.onerims-dev.freyafusion.com';
const redirectURL = process.env.REACT_APP_REDIRECT_URL;
const refreshURL = "/common-auth/refresh";


const service = axios.create({
    baseURL: basicURL,
});


service.interceptors.request.use(
    (config) => {
        const token = Cookies.get('ACCESS_TOKEN') || '';
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        if (
            config.responseType === 'arraybuffer' &&
            JSON.stringify(config.data) === '{}'
        ) {
            config.headers['Content-Type'] = 'application/octet-stream;charset=utf-8';
        } else {
            config.headers['Content-Type'] = 'application/json;charset=utf-8';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response: any) => {
        if (response.config.responseType !== "arraybuffer") {
            if (response.status) {
                switch (response.status) {
                    case 200:
                        return response.data;
                    case 400:
                    case 401:
                    case 403:
                        if (response.config?.url?.indexOf(refreshURL) > -1) {
                            window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
                            console.log(
                                "I catch err refreshURL 401/400/403 in request. will return"
                            );
                        }
                        return;
                    default:
                        console.log("error", response.data.msg);
                }
            }
        } else {
            return response;
        }
    },
    (err) => {
        if (err.config?.url?.indexOf(refreshURL) > -1) {
            console.log("I catch err refreshURL 401. will return");
            window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
            return;
        } else {
            switch (err.response.status) {
                case 401:
                    console.log("I catch err 401. will return");
                    break;
                case 403:
                    break;
                case 500:
                    if (localStorage.getItem('LOGIN_TOKEN')) {
                        console.log("There is something wrong with server, please try again later");
                    }
                    break;
                default:
            }
        }
        return Promise.reject(err);
    }
);

const refreshAuthLogic = (failedRequest: any) => {
    const userInfoJson: any = window.localStorage.getItem(
        'USER_INFO'
    );
    const userInfo: any =
        typeof userInfoJson === "string" ? JSON.parse(userInfoJson) : null;
    return service
        .post(refreshURL, {
            username: userInfo?.username,
            refreshToken: userInfo?.refreshToken,
        })
        .then((response: any) => {
            console.log("refreshURL response is", response);
            if (!response) {
                window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
                return Promise.reject();
            } else {
                window.localStorage.setItem(
                    'LOGIN_TOKEN',
                    response.data.accessToken
                );
                window.localStorage.setItem(
                    'USER_INFO',
                    JSON.stringify(response.data)
                );
                failedRequest.response.config.headers["Authorization"] =
                    "Bearer " + response.data.accessToken;
            }
            return Promise.resolve();
        })
        .catch((error: any) => {
            window.location.href = `${redirectURL}?redirect=${window.location.origin}`;
            console.log("I catch err in refreshURL. will return");
            return Promise.reject(error);;
        });
};

createAuthRefreshInterceptor(service, refreshAuthLogic);

export default service;

