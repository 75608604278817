import React, { useState } from 'react';
import { Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Button, Box, Input, Stack } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import DynamicForm from '../common/DynamicFormCreation/DynamicForm';
import { useNavigate } from "react-router-dom";
import service from '../service/apiHeaders';
import { DateTimeComponentValidationProps } from '@mui/x-date-pickers/internals/utils/validation/validateDateTime';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

interface FormField {
    Id: number;
    ApplicationName: string;
    ProductName: string;
    Region: string;
}

interface PopupFormProps {
    openpopup: boolean;
    setOpenPopup: (open: boolean) => void;
}
interface DynamicFormProps {
    fields: any[];
}
interface Errors {
    [key: string]: string;
}
const FormCreation: React.FC = () => {
    const linkTo = useNavigate();

    const [formData, setFormData] = useState<any>({});
    const [customFormData, setCustomFormData] = useState<any>({});
    const [validationErrors, setValidationErrors] = useState<any>({});


        let detailsFields = [
            {
                "type": "select",
                "field": "processTemplate",
                "required": true,
                "readonly": false,
                "disabled": false,
                "hide": false,
                "systemDefault": true,
                "originlabel": "Process Template",
                "label": "Process Template",
                "labelKey": "project.processTemplate",
                "placeholder": "Select process template",
                "multiple": false,
                "options": [],
                "asyncConfig": {
                    "url": "/syslib/dropdown-list?domain=MPR&module=Products&label=ProductCategory",
                    "labelPath": "name",
                    "valuePath": "id"
                },
                "icon": [],
                "description": "",
                "textArea": false,
                "maxRows": 1,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": true,
                "Visibility": true,
                "masterDataKey":1
            },
            {
                "type": "select",
                "field": "requestSubCategory",
                "required": true,
                "readonly": false,
                "disabled": false,
                "hide": false,
                "systemDefault": true,
                "originlabel": "Request Sub Category",
                "label": "Request Sub Category",
                "labelKey": "project.requestSubCategory",
                "placeholder": "Select request subcategory",
                "multiple": false,
                "options": [],
                "asyncConfig": {
                    "url": "/syslib/dropdown-list?domain=MPR&module=Products&label=ProductSubcategory",
                    "labelPath": "name",
                    "valuePath": "id"
                },
                "icon": [],
                "description": "",
                "textArea": false,
                "maxRows": 1,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": true,
                "Visibility": true,
                "masterDataKey": 4
            },
            {
                "field": "projectName",
                "disabled": false,
                "labelKey": "project.projectName",
                "label": "Project Name",
                "required": false,
                "readonly": true,
                "order": 1,
                "description": "Project Name",
                "placeholder": "[Auto Generated]",
                "hide": false,
                "icon": [

                ],
                "textArea": false,
                "maxRows": 2,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": true,
                "originlabel": "Project Name",
                "id": 1,
                "type": "text",
                "options": [

                ],
                "multiple": false,
                "asyncConfig": {

                }
            },
            {
                "field": "folderType",
                "disabled": true,
                "labelKey": "project.folderType",
                "label": "Folder Type",
                "required": false,
                "readonly": true,
                "order": 1,
                "description": "Folder Type",
                "placeholder": "Freyr Projects Folder",
                "hide": false,
                "icon": [

                ],
                "textArea": false,
                "maxRows": 2,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": true,
                "originlabel": "Folder Type",
                "id": 1,
                "type": "text",
                "options": [

                ],
                "multiple": false,
                "asyncConfig": {

                }
            },
            {
                "field": "folder",
                "disabled": true,
                "labelKey": "project.folder",
                "label": "Folder",
                "required": false,
                "readonly": true,
                "order": 1,
                "description": "folder",
                "placeholder": "Projects > Freyr Projects",
                "hide": false,
                "icon": [

                ],
                "textArea": false,
                "maxRows": 2,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": true,
                "originlabel": "Folder",
                "id": 1,
                "type": "text",
                "options": [

                ],
                "multiple": false,
                "asyncConfig": {

                }
            },
            {
                "type": "select",
                "field": "projectOwner",
                "required": false,
                "readonly": false,
                "disabled": false,
                "hide": false,
                "systemDefault": true,
                "originlabel": "Project Owner",
                "label": "Project Owner",
                "labelKey": "project.projectOwner",
                "placeholder": "Select project owner",
                "multiple": false,
                "options": [],
                "asyncConfig": {
                    "url": "/syslib/dropdown-list?domain=MPR&module=Products&label=ProductSubcategory",
                    "labelPath": "name",
                    "valuePath": "id"
                },
                "icon": [],
                "description": "",
                "textArea": false,
                "maxRows": 1,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": true,
                "Visibility": true,
                "masterDataKey": 11
            },
            {
                "type": "select",
                "field": "moleculeName",
                "required": true,
                "readonly": false,
                "disabled": false,
                "hide": false,
                "systemDefault": true,
                "originlabel": "Active Ingredient",
                "label": "Active Ingredient",
                "labelKey": "project.moleculeName",
                "placeholder": "Select active ingredient",
                "multiple": true,
                "options": [],
                "asyncConfig": {
                    "url": "/syslib/dropdown-list?domain=MPR&module=Products&label=ProductType",
                    "labelPath": "name",
                    "valuePath": "id"
                },
                "icon": [],
                "description": "",
                "textArea": false,
                "maxRows": 1,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": true,
                "Visibility": true,
                   "masterDataKey": 5
            },
            {
                "type": "text",
                "field": "brandName",
                "required": true,
                "readonly": false,
                "disabled": false,
                "hide": false,
                "systemDefault": true,
                "originlabel": "Brand Name",
                "label": "Brand Name",
                "labelKey": "project.brandName",
                "placeholder": "Brand Name",
                "multiple": false,
                "options": [],
                "icon": [],
                "description": "",
                "textArea": false,
                "maxRows": 1,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": true,
                "Visibility": true
            },
            {
                "field": "packagingSite",
                "label": "Packaging Site",
                "required": true,
                "readonly": false,
                "description": "Packaging Site",
                "labelKey": "project.packagingSite",
                "hide": false,
                "icon": [

                ],
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "originlabel": "Packaging Site",
                "id": 6,
                "systemDefault": true,
                "type": "select",
                "multiple": false, //true
                "asyncConfig": {
                    "url": "/Regulations/GetMetaDataFromKM?domain={domain}&module={module}&label=producttypes&tenentId={tenentId}",
                    "labelPath": "concept_name",
                    "valuePath": "concept_id"
                },
                "placeholder": "Select packaging site",
                "masterDataKey":7

            },
            {
                "field": "region",
                "label": "Region",
                "required": true,
                "readonly": false,
                "description": "Region",
                "labelKey": "project.region",
                "hide": false,
                "icon": [

                ],
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "originlabel": "Region",
                "id": 6,
                "systemDefault": true,
                "type": "select",
                "multiple": false, //true
                "asyncConfig": {
                    "url": "/Regulations/GetMetaDataFromKM?domain={domain}&module={module}&label=producttypes&tenentId={tenentId}",
                    "labelPath": "concept_name",
                    "valuePath": "concept_id"
                },
                "placeholder": "Select region",
                "masterDataKey": 10
            },

            {
                "field": "country",
                "label": "Country",
                "required": true,
                "readonly": false,
                "description": "Country",
                "labelKey": "project.country",
                "hide": false,
                "icon": [

                ],
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "originlabel": "Country",
                "id": 6,
                "systemDefault": true,
                "type": "select",
                "multiple": false, //true
                "asyncConfig": {
                    "url": "/Regulations/GetMetaDataFromKM?domain={domain}&module={module}&label=producttypes&tenentId={tenentId}",
                    "labelPath": "concept_name",
                    "valuePath": "concept_id"
                },
                "placeholder": "Select country",
                "masterDataKey": 8
            },

            {
                "type": "select",
                "field": "priority",
                "required": true,
                "readonly": false,
                "disabled": false,
                "hide": false,
                "systemDefault": true,
                "originlabel": "Priority",
                "label": "Priority ",
                "labelKey": "project.priority",
                "placeholder": "Select priority",
                "multiple": false,
                "options": [],
                "asyncConfig": {
                    "url": "/product/productFamily/list-code",
                    "labelPath": "code",
                    "valuePath": "id"
                },
                "icon": [],
                "description": "",
                "textArea": false,
                "maxRows": 1,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": true,
                "Visibility": false,
                "masterDataKey":9
            },
            {
                "type": "text",
                "field": "description",
                "required": false,
                "readonly": false,
                "disabled": false,
                "hide": false,
                "systemDefault": true,
                "originlabel": "Description",
                "label": "Description",
                "labelKey": "project.description",
                "placeholder": "Description",
                "multiple": false,
                "options": [],
                "icon": [],
                "description": "",
                "textArea": false,
                "maxRows": 3,
                "field_xs": 12,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": false,
                "Visibility": false
            },
            {
                "type": "text",
                "field": "specialInstructions",
                "required": true,
                "readonly": false,
                "disabled": false,
                "hide": false,
                "systemDefault": true,
                "originlabel": "Special Instructions",
                "label": "Special Instructions",
                "labelKey": "project.specialInstructions",
                "placeholder": "Special instructions",
                "multiple": false,
                "options": [],
                "icon": [],
                "description": "",
                "textArea": false,
                "maxRows": 3,
                "field_xs": 12,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": false,
                "Visibility": false
            },
            {
                "type": "date",
                "field": "completeProjectByDate",
                "required": true,
                "readonly": false,
                "disabled": false,
                "hide": false,
                "systemDefault": true,
                "originlabel": "Complete Date",
                "label": "Complete Date",
                "labelKey": "project.completeProjectByDate",
                "placeholder": "Select complete date",
                "multiple": false,
                "options": [],
                "icon": [],
                "description": "",
                "textArea": false,
                "maxRows": 1,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": true,
                "Visibility": false
            },
            {
                "field": "artworkComponents",
                "systemDefault": true,
                "disabled": false,
                "labelKey": "project.artworkComponents",
                "label": "Artwork Components",
                "required": false,
                "readonly": false,
                "description": "Associated Components",
                "placeholder": "Upload Components",
                "hide": false,
                "icon": [

                ],
                "maxRows": 3,
                "field_xs": 4,
                "field_xl": 4,
                "field_md": 4,
                "field_lg": 4,
                "gridDefault": false,
                "originlabel": "ArtworkComponents",
                "id": 2,
                "type": "fileupload",
                "options": [

                ],
                "multiple": true,
                "textArea": false,
                "asyncConfig": {

                }
            }
    ]

    const handleBackToGrid = () => {
        setTimeout(() => {
            linkTo("/Requests", {});
        }, 50);
    };

    detailsFields = detailsFields.map(field => ({
        ...field,
        value: formData ? formData?.[field.field] : "",
        customValue: customFormData ? customFormData?.[field.field] : "",
        onChange: (value: string, customValue: string) => handleChange(field.field, value, customValue),
        required: field.required !== undefined ? field.required : false
    }));

    const handleChange = (fieldName: string, value: any, customValue: any) => {
        const newFormData = { ...formData, [fieldName]: value };
        const newCustomFormData = { ...customFormData, [fieldName]: customValue }
        setFormData(newFormData);
        setCustomFormData(newCustomFormData);
        //setValidationErrors((prevErrors: any) => {
        //    const newErrors = { ...prevErrors };
        //    delete newErrors[fieldName];
        //    return newErrors;
        //});
    };

   
    

    const moveToRequests = () => {
        linkTo('/Requests');
    };
    const updateFormData = (newFormData: any) => {
        setFormData(newFormData);
    };

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [popupShown, setPopupShown] = useState(false);
    const getFieldValidationMessage = (fieldType: string, fieldValue: any, fieldLabel: string, required: boolean): string => {
        if (!required) {
            return '';
        }
        switch (fieldType) {
            case 'date':
                return fieldValue === undefined || fieldValue === null || fieldValue === '' ? `Please Update the ${fieldLabel}` : '';
            case 'text':
                return fieldValue ? '' : `Please fill ${fieldLabel}`;
            case 'fileupload':
                return fieldValue ? '' : `Please upload ${fieldLabel}`;
            case 'textWithAdd':
                return fieldValue ? '' : `Please add ${fieldLabel}`;
            case 'treeview':
                return fieldValue ? '' : `Please select ${fieldLabel}`;
            case 'select':
                return fieldValue ? '' : `Please select ${fieldLabel}`;
            case 'document':
                return fieldValue ? '' : `Please upload ${fieldLabel}`;
            case 'richTextBox':
                return fieldValue ? '' : `Please Enter ${fieldLabel}`;
            default:
                return '';
        }
    };

    const validateForm = () => {
        const allFields = [...detailsFields];
        const errors: Errors = {};

        allFields.forEach(field => {
            // Only validate if the field is required
            if (field.required && !formData[field.field]) {
                errors[field.field] = getFieldValidationMessage(field.type, formData[field.field], field.label, true);
            }
        });

        const errorMessages = Object.values(errors);

        setValidationErrors(errors);
        const hasErrors = Object.keys(errors).length > 0;
        return { isValid: !hasErrors, validationErrors: errors };
    };

    const addRequest = () => {
        const allFields = [...detailsFields];
        const { isValid, validationErrors } = validateForm();

        const errorMessages = Object.entries(validationErrors)
            .filter(([field, message]: [string, string]) => message !== '')
            .map(([field, message]: [string, string]) => <div key={field}>{message}</div>);

        if (!popupShown && errorMessages.length > 0) {
            const errorMessage = <div>{errorMessages}</div>;
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 1000 });
            setPopupShown(true);
        } else if (!popupShown && isValid) {
            enqueueSnackbar('Request added successfully', { variant: 'success', autoHideDuration: 1000 });
            setPopupShown(true);
        }
        const remainingRequiredFields = Object.entries(validationErrors)
            .filter(([field, message]: [string, string]) => message === '')
            .map(([field, message]: [string, string]) => field);
        if (remainingRequiredFields.length > 0) {
            const remainingErrorMessages = remainingRequiredFields.map(f => {
                const fieldLabel = allFields.find(field => field.field === f)?.label ?? '';
                const fieldType = allFields.find(field => field.field === f)?.type ?? '';
                return getFieldValidationMessage(fieldType, formData[f], fieldLabel, true);
            });

            const remainingErrorMessage = <div>{remainingErrorMessages}</div>;
            enqueueSnackbar(remainingErrorMessage, { variant: 'error', autoHideDuration: 1000 });

        }
        else if (remainingRequiredFields.length == 0 && Object.keys(validationErrors).length == 0) {
            SaveRequestData();
        }

        setPopupShown(false);
    };  

    const [cookies] = useCookies(['USER_TENANT_ID', 'DOMAIN', 'USER_PRIVILEGES', 'ACCESS_TOKEN']);
    let tenantId: any;

    const SaveRequestData = async () => {
        try {
            tenantId = cookies.USER_TENANT_ID || 1;
            const jsonData = JSON.stringify(formData);


            const dynamicData = {
                "templateId": 1,
                "formFieldValues": jsonData,
                "parentId": 1,
                "projectStatus": 1,
                "inputFormFields": null,
                "companyId": 2,
                "createdBy": 2,
                "createdDate": new Date(),
                "modifiedBy": 3,
                "modifiedDate": new Date(),
                "status": 1,
                "projectNumber": "1",
                "subProcessNumber": "1"
            };
          
            //const tenantID = tenantId
            const response = await service.post('/api/Dynamic/SaveRequestData', dynamicData, {
                params: {
                    tenantID: tenantId
                }
            });
            linkTo('/Requests');

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    return (
        <div>

            <Stack>
               
                <div className="view-heading_main">
                    <div className="view-heading_row">
                        <div className="view-heading-item">
                            <a href="" onClick={moveToRequests}>Back</a>
                        </div>
                       
                    </div> </div>
                <div className="row">
                    <div className="col-9 display-inline-block p-r-5">
                        <div className="view-panel m-r-0">
                            <div className="view-panel_body p-r-10 m-t-10">

                                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">

                                        <Box sx={{ width: '100%', typography: 'body1', flex: 1, marginRight: '16px' }}>
                                        <DynamicForm fields={detailsFields} updateFormData={updateFormData} validationErrors={validationErrors}
                                            onChange={handleChange} />
                                    </Box>
                                </Stack>
                                <div className='row m-t-30 m-b-40 add-buttons' >
                                    <div className='col-12 right'> 
                                        {/*<Button variant="contained" size="small" type="button">Save As Draft</Button>*/}
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                type="button"
                                                onClick={addRequest}
                                            >
                                                Submit
                                        </Button>
                                        <Button variant="outlined" size="small" type="button" onClick={handleBackToGrid}>Cancel</Button>
                                    </div>
                                </div>   

                            </div>
                        </div>
                    </div>
                </div>
            </Stack>

        </div>

    );

};

export default FormCreation;
