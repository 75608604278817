import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "../src/app/common/notification/NotistackPlus";

LicenseInfo.setLicenseKey("3ca440339979337160406e3219059976Tz03OTAwNCxFPTE3MzE5MzM3OTgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
 /* <React.StrictMode>
    <App />
    </React.StrictMode>*/

     <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <SnackbarUtilsConfigurator />
        <React.StrictMode>
        <App />
        </React.StrictMode>
        </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
