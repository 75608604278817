import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import service from '../service/apiHeaders'
import {
    DataGridPremium,
    GridToolbar,
    useGridApiRef,
    useKeepGroupedColumnsHidden,
    GridPinnedColumns,
} from '@mui/x-data-grid-premium';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Tooltip } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';

const GetDisplay = () => {
    const linkTo = useNavigate();

    const [rowData, setData] = useState([]);

    const [cookies] = useCookies(['USER_TENANT_ID', 'DOMAIN', 'USER_PRIVILEGES', 'ACCESS_TOKEN']);
    let tenantId: any;
    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
            try {
                tenantId = cookies.USER_TENANT_ID || 1;

                // Example GET request
                const resData: any = await service.get('/api/Dynamic/GetDisplayData?tenantId=' + tenantId); // Replace '/api/data' with your actual endpoint

                const rows = resData.result.map((row: { formfieldvalues: any; }) =>
                    row.formfieldvalues
                );
                const dataArray = rows.map((jsonString: string, index: any) => {
                    const parsedData = JSON.parse(jsonString);
                    return {
                        ID: index + 1,
                        Name: parsedData.moleculeName,
                        
                        ProcessTemplate: parsedData.processTemplate,
                        RequestSubCategory: parsedData.requestSubCategory,
                        Priority: parsedData.priority,
                        ProjectOwner: parsedData.projectOwner,
                        CompleteProjectByDate: parsedData.completeProjectByDate,
                        BrandName: parsedData.brandName,
                        SpecialInstructions: parsedData.specialInstructions
                    };
                });

                setData(dataArray);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the fetch data function when the component mounts

    }, []);


    const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumns>({
        right: ["action"],
    });


    const [openpopup, setOpenpopup] = useState(false);


    const handleAddRequestClick = () => {
        setTimeout(() => {
            linkTo("/Create", {});
        }, 50);
    };

    const columns = [ // Define your columns
        
        
        { field: 'ProcessTemplate', headerName: 'Process Template', width: 200 },
        { field: 'RequestSubCategory', headerName: 'Request Sub Category', width: 200 },
        {
            field: 'Name', headerName: 'Active Ingredient', width: 150
        },
        { field: 'BrandName', headerName: 'Brand Name', width: 150 },

        { field: 'Priority', headerName: 'Priority', width: 120 },
        { field: 'ProjectOwner', headerName: 'Project Owner', width: 120 },
        { field: 'CompleteProjectByDate', headerName: 'Complete Date', width: 200 },
        { field: 'SpecialInstructions', headerName: 'Special Instructions', width: 150 },

        {
            field: "action",
            hideable: false,
            headerName: "Action",
            sortable: true,
            disableColumnMenu: false,
            filterable: true,

            width: 70,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <div className="ActionIcons">
                            <Button onClick={(e) => alert('action click')}>
                                <MoreVertIcon sx={{ color: "gray" }} />

                            </Button>
                        </div>
                    </>
                );
            },

        },
    ];




    const apiRef = useGridApiRef();

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {

            // rowGrouping: {

            //   model: ['applicationNumber'],
            // },
            sorting: {
                sortModel: [{ field: '__row_group_by_columns_group__', sort: 'asc' }],
            },
            aggregation: {
                model: {
                    quantity: 'sum',
                },
            },
        },
    });
    return (
        <Box sx={{ height: 520, width: '95%' }}>
            <Button variant="contained" className="pull-right" onClick={handleAddRequestClick}>Create</Button>
            <DataGridPremium
                getRowId={(row: any) => row.ID}
                className='react-table'
                rows={rowData}
                columns={columns} // Provide columns prop
                apiRef={apiRef}
                loading={false} // Assuming data is not loading
                columnHeaderHeight={32}
                rowHeight={38}
                disableRowSelectionOnClick
                initialState={initialState}
                slots={{ toolbar: GridToolbar }}
                pinnedColumns={pinnedColumns}
                pageSizeOptions={[10, 20, 50]}
                pagination
            />
        </Box>
    );
}

export default GetDisplay;
