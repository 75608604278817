import React from 'react';
import { RouterProvider } from "react-router-dom";
import { Router } from './app/routes/route';

function App() {
    return (
        <div className="App">
            <RouterProvider router={Router} />
        </div>
    );
    /*const handleClick = () => {
        <h1>testt</h1>
    };
    return (
        <div>
            <header className="App-header">
                Freya Artwork
            </header>
            <h1>Button Click Counter</h1>
            <p>Number of clicks: 1</p>
            {}
            <button onClick={handleClick}>Click Me</button>
        </div>
    );*/
}

export default App;
