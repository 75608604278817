import React from 'react';
import { IconButton, InputAdornment, InputLabel, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

interface DateFieldProps {
    objJson: any;
    value: any;
    customValue: any;
    onChange: (value: any, customValue: any) => void;
}

const DateField: React.FC<DateFieldProps> = ({
    objJson,
    value,
    customValue,
    onChange,
}) => {
    const dateFormat = "DD/MM/YYYY";
    const [open, setOpen] = React.useState(false);

    const handleOpen = (status: boolean) => {
        if (status && (objJson.readonly || objJson.disabled)) return;
        setOpen(status);
    };
    /*const setValue = (val: any) => {
        onChange?.(val ? new Date(val) : "");
    }
    const ConvertDate = (val: any) => {
        const originalDate = new Date(val);
        const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
        const formattedDate = originalDate.toLocaleDateString('en-US', options);

        return formattedDate
    }*/

    return (
                <div>

            <InputLabel required={objJson.required}>{objJson.label}</InputLabel>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    className="custom-date-picker"
                    //inputFormat={dateFormat}
                    value={value}
                    onChange={(newVal: any) => {
                        onChange?.(new Date(newVal),new Date(newVal));
                    }}
                    open={open}
                    onOpen={() => handleOpen(true)}
                    onClose={() => handleOpen(false)}
                    minDate={objJson?.minDate}
                    maxDate={objJson?.maxDate}
                    disabled={objJson.disabled}
                    readOnly={objJson.readonly}
                   /* renderInput={(params: any) => {
                        return (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    value: value ? dayjs(value).format(dateFormat) : "",
                                    readOnly: true,
                                    placeholder: objJson.placeholder ?? "Select Date",
                                }}
                                style={{ width: "100%" }}
                                //error={rawErrors.length > 0}
                                size="small"
                                onClick={() => handleOpen(true)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {
                                            // value && customValue
                                             (
                                                <HighlightOffOutlinedIcon
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        ev.stopPropagation();
                                                        if (objJson.disabled) {
                                                            return
                                                        } else {
                                                            onChange(undefined,undefined);
                                                        }
                                                    }}
                                                    sx={{
                                                        marginX: "5px",
                                                        fontSize: "14px",
                                                        color: "GrayText",
                                                        "&:hover": objJson.disabled ? {} : {
                                                            color: "Highlight",
                                                            cursor: "pointer",
                                                        },
                                                    }}

                                                ></HighlightOffOutlinedIcon>
                                            )}
                                            <IconButton
                                                size="small"

                                                disabled={objJson.disabled}
                                            >
                                                <CalendarMonthOutlinedIcon

                                                ></CalendarMonthOutlinedIcon>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        );
                    }}*/
                />
            </LocalizationProvider>
        </div>

       /* <div >
            <InputLabel required={objJson.required}>{objJson.label}</InputLabel>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    className="custom-date-picker"
                    format={dateFormat}
                    value={value ?? ""}
                    onChange={(newVal) => {
                        onChange?.(new Date(newVal));
                    }}
                    open={open}
                    onOpen={() => handleOpen(true)}
                    onClose={() => handleOpen(false)}
                    //minDate={objJson?.minDate}
                    //maxDate={objJson?.maxDate}
                    disabled={objJson?.disabled}
                    readOnly={objJson?.readonly}
                />
            </LocalizationProvider>
        </div>*/
    );
};

export default DateField;

