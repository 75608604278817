import {
    TextField,
    InputLabel,
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteChangeDetails,
    Chip,
    InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import service from '../../service/apiHeaders';
import { useCookies } from 'react-cookie';

/** The `SelectWidget` is a widget for rendering dropdowns.
 *  It is typically used with string properties constrained with enum options.
 *
 * @param props - The `WidgetProps` for this component
 */

interface OptionItem {
    label: string;
    value: string | number;
    type: number;
}
interface SelectFieldProps {
    objJson: any;
    value: any;
    //customValue: any;
    onChange: (value: any, customValue: any) => void;

}

type optionList = {
    label: string;
    value: string | number;
    disabled?: boolean;
    type: number;
};

export const mockDropdownListData: Record<
    string,
    Array<Omit<OptionItem, "label"> & { label: string }>
> = {
};


const SelectWidget: React.FC<SelectFieldProps> = ({
    objJson,
    value,
    //customValue,
    onChange
}) => {

    const uniqueKey = `${objJson.id}-unique-select`;

    const multiple = objJson.multiple;
    const multipleChip = !objJson.multiple;
    const [autocompleteValue, setAutocompleteValue] = useState<
        OptionItem | OptionItem[] | null
    >(multiple ? [] : null);

    /*const { resOptionList, fetchLoading } = useCustomSelect(
        objJson.asyncConfig,
        uniqueKey,
        value,
        !mockDropdownListData?.[objJson.id],

    );*/

    const [optionList, setOptions] = useState<Array<optionList>>([]);
    const [cookies] = useCookies(['USER_TENANT_ID', 'DOMAIN', 'USER_PRIVILEGES', 'ACCESS_TOKEN']);
    let tenantId: any;
    useEffect(() => {
        const fetchData = async () => {
            try {
                tenantId = cookies.USER_TENANT_ID || 1;
                // Example GET request
                const resData: any = await service.get('/api/Dynamic/GetMasterData?tenantId=' + tenantId); // Replace '/api/data' with your actual endpoint
                const dataArray = resData.result.map((row: any, index: any) => {
                    return {
                        label: row.name,
                        value: row.id,
                        type: row.type
                    };
                });
                setOptions(dataArray);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);


    const handleValueChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: any,
        //customValue: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any> | undefined

    ) => {
        let newValue = value?.value ?? undefined;
        if (multiple) {
            newValue = value?.length
                ? value.map((item: OptionItem) => item.value)
                : undefined;
        }

        let newValue1;
        if (Array.isArray(value)) {
            // Format selected values as an array with specified fields
            newValue1 = value.map((item: OptionItem) => ({
                is_deleted: false,
                name: item.label,
                id: item.value
            }));
        } else {
            // Format single selected value
            newValue1 = [{
                is_deleted: false,
                name: value.label,
                id: value.value
            }];
        }

        // Pass formatted data to parent component
        onChange?.(
            newValue, newValue1
        );
        setAutocompleteValue(multiple ? newValue ?? [] : newValue ?? null);
        // Update autocomplete value state
        setAutocompleteValue(value);
    };

    useEffect(() => {
        if (
            !optionList ||
            optionList?.length === 0 ||
            !value ||
            (Array.isArray(value) && value?.length === 0)
        ) {
            return;
        }
        // fetch the option list init. Remake the value.
        // refresh the option list by dependencyVars. clean the value.
        if (!multiple) {
            singleSelect();
        }
        if (multiple) {
            multiSelect();
        }
    }, [optionList, value]);

    function singleSelect() {
        const newValue = optionList?.find((el) => {
            if (typeof value !== "object") {
                return value && `${el?.value}` === `${value}`;
            }
            return value && `${el?.value}` === `${value?.value}`;
        });

        setAutocompleteValue(newValue ?? null);
    }

    function multiSelect() {
        const newValue = optionList?.filter((el) => {
            if (!value || !Array?.isArray(value) || value?.length === 0) {
                return false;
            }
            const res = value?.some((item) => {
                if (typeof item !== "object") {
                    return (
                        item && `${el?.value}`?.toLowerCase() === `${item}`?.toLowerCase()
                    );
                }
                const some =
                    value &&
                    `${el?.value}`?.toLowerCase() === `${item?.value}`?.toLowerCase();
                return some;
            });
            return res;
        });
        setAutocompleteValue(newValue ?? []);
    }

    const filterOptions = (options: OptionItem[], controlField: any) => {
        return options.filter(option => option.type === controlField);
    };
    return (
        <div>

            <InputLabel required={objJson.required}>{objJson.label}</InputLabel>
            <Autocomplete
                id={objJson.field}
                className="custom-autocomplete"
                limitTags={2}
                disabled={objJson.disabled}
                readOnly={objJson.readonly}
                multiple={multiple}
                disableCloseOnSelect={!!multiple}
                value={autocompleteValue}
                options={filterOptions(optionList, objJson.masterDataKey)}//{optionList}
                getOptionLabel={(option) => option?.label || ""}

                isOptionEqualToValue={(option, value) => {
                    if (typeof value === "object" && "value" in value && value?.value)
                        return option?.value === value?.value;
                    const res = option?.value === (value as unknown as string | number);
                    return res;
                }}
                onChange={handleValueChange}

                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            required={objJson.required}
                            placeholder={objJson.placeholder}
                            InputProps={{
                                ...params?.InputProps,
                                endAdornment:
                                    <>
                                        {
                                            /*fetchLoading ? (
                                                <InputAdornment position="end">
                                                    <CircularProgress color="inherit" size={20} />

                                                </InputAdornment>
                                            ) :*/
                                            params?.InputProps.endAdornment
                                        }
                                    </>
                            }}
                            inputProps={{
                                ...params.inputProps,
                                onKeyDown: (e) => {
                                    if (e.key.toLocaleLowerCase() === 'enter') {
                                        e.preventDefault();
                                    }
                                },
                            }}
                        />
                    );
                }}

            />
        </div>
    );
}
export default SelectWidget;