import React from 'react';
import Dashboard from '../FormCreation/dashboard';
import FormCreation from '../FormCreation/formCreation';
import GridDisplay from '../FormCreation/gridDisplay';
import { Navigate, createBrowserRouter } from "react-router-dom";
import { lazy } from "react";

const routesForm = [
    {
        path: "/*",
        element: <Dashboard />,
    },
    {
        path: "/Create",
        element: <FormCreation />,
    },
    {
        path: "/Requests",
        element: <GridDisplay />,
    },
];

const Router = createBrowserRouter(routesForm);

export { Router };