import React, { useEffect, useState } from 'react';
import TextField from './TextField';
import { Grid } from '@mui/material';
import DateField from './DateField';
//import SelectField from './SelectField';
import SelectWidget from './MultiSelectField';
import MultiFileUploadField from './MultiFileUploadField';

interface DynamicFormProps {
    fields: any[];
    updateFormData: (newFormData: any) => void;
    validationErrors: any;
    onChange: (fieldName: string, value: any, customValue: any) => void;
}
    const DynamicForm: React.FC<DynamicFormProps> = ({ fields, updateFormData, validationErrors, onChange }) => {

        const [formData, setFormData] = useState<any>({});

        useEffect(() => {
        }, [formData, validationErrors]);

    return (
              
                       
                        
        <form>
            <Grid container={true} spacing={2} style={{ marginTop: "10px", marginLeft: "5px" }}>
                {fields.map((field, index) => (
                    <Grid
                        item={true}
                        xs={field?.["field_xs"] || 12}
                        xl={field?.["field_xl"] || 12}
                        md={field?.["field_md"] || 12}
                        lg={field?.["field_lg"] || 12}
                        key={index} style={{ marginBottom: "10px" }}
                    >
                        {/*{field.type === 'date' && (*/}
                        {/*    <DateField objJson={field} value={field.value} onChange={field.onChange} />*/}
                        {/*)}*/}
                        {/*{field.type === 'select' && (*/}
                        {/*    <SelectField objJson={field} value={field.value} onChange={field.onChange} />*/}
                        {/*)}*/}
                        {field.type === 'date' && (
                            <DateField objJson={field} value={field.value} customValue={field.customValue} onChange={field.onChange} />
                        )}
                        {/*//customValue={field.customValue} */}
                        {field.type === 'select' && (
                            <SelectWidget objJson={field} value={field.value} onChange={field.onChange} />
                        )}
                        {/*{field.type === 'fileupload' && (*/}
                        {/*    <MultiFileUploadField objJson={field} value={field.value} customValue={field.customValue} onChange={field.onChange} />*/}
                        {/*)}*/}
                        {(field.type === 'text') && (
                            <TextField objJson={field} value={field.value} onChange={field.onChange} />
                        )}
                        
                    </Grid>


                ))}
            </Grid>
        </form>
    );
};

export default DynamicForm;